import { graphql, navigate, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import Collapse from '../images/icons/Collapse'
import Button from './common/Button'
import NavItem from './NavItem'
import Sidebar from './Sidebar'

type DataProps = {
    showFloatHeader: boolean
    showSideBar: boolean,
    setShowSideBar: (show: boolean) => void
}

const FloatHeader:React.FC<DataProps> = ({showFloatHeader, showSideBar, setShowSideBar}) => {

    const data = useStaticQuery(graphql`
        query FloatHeaderQuery {
            Logo: file(name: {eq: "white_logo"}, absolutePath: {regex: "/icons/"}) {
            childImageSharp {
                gatsbyImageData(height:34, placeholder: NONE)
            }
            }

            WhiteSpeaker: file(name: {eq: "white_speaker"}, absolutePath: {regex: "/icons/"}) {
                childImageSharp {
                    gatsbyImageData(width:22, height:22, placeholder: NONE)
                }
                }
        }      
    `)

    const logo = getImage(data.Logo)
    const whiteSpeaker = getImage(data.WhiteSpeaker)

    const [zindex, setZindex] = useState(-1000)

    React.useEffect(()=> {
        if(showFloatHeader) {
            setZindex(1000)
        }else {
            setZindex(-1000)
        }
    }, [showFloatHeader])


    return (
        <div id="float-header" className={`flex fixed w-full px-6 py-4 sm:px-6 md:px-16 lg:px-20 xl:px-24 justify-between items-center bg-primary transition-opacity duration-300 ${showFloatHeader ? 'opacity-100' : 'opacity-0'}`} style={{zIndex: zindex}}>

            {/* LOGO SECTION */}
            <div className="cursor-pointer" onClick={()=>navigate('/')}>
                <GatsbyImage image={logo} alt="Lucid Reach Logo" className="w-32 xs:w-40 sm:w-auto cursor-auto" />
            </div>

            {/* NAV LINKS */}
            <div className={`hidden lg:inline-flex space-x-3 `}>
                <GatsbyImage image={whiteSpeaker} alt="Speaker" />
                <p className="text-base text-white font-medium">Boost Sales with Outcome-Driven Email Marketing</p>
            </div>

            {/* BUTTON */}

            <div className="hidden lg:block">
                <Button to="https://app.lucidreach.com/" width={`w-12 lg:w-auto`} background="bg-success font-medium" text="Start for Free" borderRadius="rounded-14px" />
            </div>

            <div className="block lg:hidden" onClick={()=> setShowSideBar(true)}>
                <Collapse color="text-white"/>
            </div>
        </div>
    )
}

export default FloatHeader
