import { Link } from 'gatsby'
import React from 'react'
import CloseIcon from '../images/icons/Close'
import Button from './common/Button'

type DataProps = {
    showSideBar: boolean,
    setShowSideBar: (value: boolean) => void
}

const Sidebar:React.FC<DataProps> = ({showSideBar, setShowSideBar}) => {
    return (
        <>
            <div id="sidebar" className={`h-screen lg:hidden absolute transition-width duration-500 ease-in-out ${showSideBar ? 'right-0 w-4/5 md:w-3/5' : 'right-0 w-0 overflow-hidden'} bg-white`} style={{zIndex: 100001}}>

                {/* Close Icon */}
                <div className="px-6 py-6 flex justify-end" onClick={()=> setShowSideBar(false)}>
                    <CloseIcon />
                </div>

                <div className="px-7 space-y-7 flex flex-col">
                    <Link onClick={()=> setShowSideBar(false)} to="#compare-us" className="text-sm font-medium text-font_primary capitalize">Compare Us</Link>
                    <Link onClick={()=> setShowSideBar(false)} to="#features" className="text-sm font-medium text-font_primary capitalize">Features</Link>
                    <Link onClick={()=> setShowSideBar(false)} to="#pricing" className="text-sm font-medium text-font_primary capitalize">Pricing</Link>
                </div>

                <div className="p-7 w-full mx-auto">
                    <Button to="https://app.lucidreach.com/" width={`w-full`} text="Get early access" borderRadius="rounded-lg" classNames="text-sm" />
                </div>
            </div>
        </>
    )
}

export default Sidebar
