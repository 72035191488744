import React from 'react'

type DataProps = {
    background?: string,
    textColor?: string,
    width?: string,
    text?: string,
    borderRadius?: string,
    classNames?: string
    to?: string
}

const Button:React.FC<DataProps> = ({background="bg-primary", textColor="text-white", width="w-auto", borderRadius="rounded-md", text, classNames, to}) => {
    return (
        <div className="w-full lg:w-auto"> 
            <a 
                className={`px-7 py-3 ${width} text-center flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary ${background} ${textColor} ${borderRadius} ${classNames}`}
                href={to}
                target="_blank"
            >
                {text}
            </a>
        </div>
    )
}

export default Button
