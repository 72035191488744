import { navigate } from 'gatsby-link'
import React from 'react'

const Footer:React.FC = () => {
    return (
        <div className="text-center my-5 mx-auto w-full " >
            <p className="text-font_secondary text-sm md:text-base lg:text-lg font-normal">Copyright &copy; 2021 <span className="text-primary cursor-pointer" onClick={()=> navigate('/')}>lucidereach.com</span></p>
        </div>
    )
}

export default Footer
