import { Link } from 'gatsby'
import React from 'react'

type DataProps = {
    to: string,
    children: React.ReactNode
}

const NavItem:React.FC<DataProps> = ({to, children}) => {
    return (
        <div>
            <Link to={to} className="text-15px text-font_primary font-medium capitalize cursor-pointer">
                {children}
            </Link>
        </div>
    )
}

export default NavItem
