import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import FloatHeader from './FloatHeader'
import Footer from './Footer'
import Header from './Header'
import Sidebar from './Sidebar'

type DataProps = {
    children: React.ReactNode
}

const Layout:React.FC<DataProps> = ({children}) => {

    const layoutQueryData = useStaticQuery(graphql`
        query LayoutQuery {
            HomeBanner: file(name: {eq: "home_banner"}, absolutePath: {regex: "/images/"}) {
            childImageSharp {
                gatsbyImageData(placeholder: NONE, quality: 100)
            }
            }

            HomeBannerPattern: file(name: {eq: "home_banner_pattern"}, absolutePath: {regex: "/images/"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, quality: 100)
                }
            }
        }
    
    `)


    const [showSideBar, setShowSideBar] = useState(false)
    const [headerHeight, setHeaderHeight] = useState(0)
    const [screenHeight, setScreenHeight] = useState(0)
    const [showFloatHeader, setShowFloatHeader] = useState(false)

    const homeBannerImage = getImage(layoutQueryData.HomeBanner)
    const homeBannerPatternImage = getImage(layoutQueryData.HomeBannerPattern)

    React.useEffect(()=> {
        setScreenHeight(screen.height);
    })

    useEffect(()=> {
        document.getElementById('layout').addEventListener('scroll', () => {
            document.getElementById('layout').scrollTop > 50 ? setShowFloatHeader(true) : setShowFloatHeader(false);
        })
    })


    React.useEffect(()=> {
        setHeaderHeight(document.getElementById('header')?.offsetHeight)
    }, [])

    return (
        <div id="layout" className="w-screen h-screen overflow-y-auto overflow-x-hidden">

            {showSideBar && <div className="absolute w-screen h-screen bg-black bg-opacity-60" style={{zIndex:1000}}></div>}

            <Sidebar showSideBar={showSideBar} setShowSideBar={setShowSideBar} /> 

            {/* FLOAT HEADER */}
            <FloatHeader showFloatHeader={showFloatHeader} showSideBar={showSideBar} setShowSideBar={setShowSideBar}/>

            <section id="home" className="relative h-screen bg-home_background">

                {/* HEADER SECTION */}
                <Header showSideBar={showSideBar} setShowSideBar={setShowSideBar} />

                {/* HOME SECTION */}
                <div className="relative w-full overflow-hidden" style={{height: `calc(100% - ${headerHeight}px)`}}>

                    <div className={`relative w-full mx-auto sm:w-3/5 md:w-4/5 lg:w-3/5 px-1 ${screenHeight > 768 && 'pt-10'} md:pt-0 sm:px-6 flex flex-col items-center text-center`}>

                        <h1 className="mt-3 md:mt-0 lg:mt-0 py-2 md:py-5 lg:py-5 sm:py-0 text-font_primary text-base  sm:text-xl md:text-2xl lg:text-40px lg:leading-52px font-bold  capitalize">
                            Outcome-Driven Email Marketing Solution <span className="text-primary">personalized</span> for Every Industry
                        </h1>

                        <h4 className="w-85% text-font_secondary font-normal text-sm md:text-base lg:text-lg leading-7 text-opacity-80">Boost sales, Increase Customer Value, and Grow your business with the most affordable email marketing software customized for every industry.</h4>

                        {/* Desktop View */}
                        <div className="w-full py-6 hidden lg:block">
                            <div className="optinly-embed-popup-4bae50cf-baa7-4823-9e23-7087657e4c34"></div>
                        </div>

                        {/* Mobile view */}
                        <div className="w-full py-6 block lg:hidden">
                            <div className="optinly-embed-popup-83ab0a9a-1904-4086-88fe-b1b11b574c34"></div>
                        </div>

                    </div>
                        
                </div>

                <div className="hidden lg:flex absolute bottom-0 left-0 right-0 w-4/5 mx-auto items-center justify-center">
                    <GatsbyImage image={homeBannerPatternImage} alt="Home Banner Pattern" className="w-full" />
                </div>

                <div className={`absolute ${screenHeight < 570 ? 'left-0 right-0 -bottom-24' : 'left-0 right-0 bottom-3'} md:-bottom-48 lg:-bottom-64 w-11/12 lg:w-4/5 mx-auto flex items-center justify-center home-image-shadow`} style={{zIndex: 100}}>
                    <GatsbyImage image={homeBannerImage} alt="Home Banner" className="w-full lg:w-70% h-full rounded-lg md:rounded-2xl lg:rounded-32px" />
                </div>

            </section>
            
            <main>
                {children}
            </main>
            <Footer />
        </div>
    )
}

export default Layout
