import React from 'react'

type DataProps = {
    color?: string
}

const Collapse:React.FC<DataProps> = ({color = 'text-font_primary'}) => {
    return (
        <>
            <svg className={`opacity-80 ${color} `} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.1667 3.66666H11.9167C11.4104 3.66666 11 4.07706 11 4.58332C11 5.08958 11.4104 5.49999 11.9167 5.49999H20.1667C20.6729 5.49999 21.0833 5.08958 21.0833 4.58332C21.0833 4.07706 20.6729 3.66666 20.1667 3.66666Z" fill="currentColor"/>
                <path d="M20.1665 10.0833H1.83317C1.32691 10.0833 0.916504 10.4937 0.916504 11C0.916504 11.5062 1.32691 11.9166 1.83317 11.9166H20.1665C20.6728 11.9166 21.0832 11.5062 21.0832 11C21.0832 10.4937 20.6728 10.0833 20.1665 10.0833Z" fill="currentColor"/>
                <path d="M20.1665 16.5H5.95817C5.45191 16.5 5.0415 16.9104 5.0415 17.4167C5.0415 17.9229 5.45191 18.3333 5.95817 18.3333H20.1665C20.6728 18.3333 21.0832 17.9229 21.0832 17.4167C21.0832 16.9104 20.6728 16.5 20.1665 16.5Z" fill="currentColor"/>
            </svg>
        </>
    )
}

export default Collapse
