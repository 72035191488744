import { graphql, navigate, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import Collapse from '../images/icons/Collapse'
import Button from './common/Button'
import NavItem from './NavItem'
import Sidebar from './Sidebar'

type DataProps = {
    showSideBar: boolean,
    setShowSideBar: (value: boolean) => void
}

const Header:React.FC<DataProps> = ({showSideBar, setShowSideBar}) => {

    const data = useStaticQuery(graphql`
        query HeaderQuery {
            Logo: file(name: {eq: "logo"}, absolutePath: {regex: "/icons/"}) {
            childImageSharp {
                gatsbyImageData(height:40, placeholder: NONE)
            }
            }
        }      
    `)

    const logo = getImage(data.Logo)

    return (
        <div id="header" className="w-screen px-6 py-6 sm:px-6 md:px-16 md:py-7 lg:px-20 lg:py-8 xl:px-24 flex justify-between items-center">

            {/* LOGO SECTION */}
            <div className="cursor-pointer" onClick={()=>navigate('/')}>
                <GatsbyImage image={logo} alt="Lucid Reach Logo" className="w-32 xs:w-40 sm:w-auto cursor-pointer" />
            </div>

            {/* NAV LINKS */}
            <div className={`hidden lg:inline-flex lg:items-center lg:space-x-14`}>
                <NavItem to="#compare-us" >Compare us</NavItem>
                <NavItem to="#features" >Features</NavItem>
                <NavItem to="#pricing" >Pricing</NavItem>
            </div>

            {/* BUTTON */}

            <div className="hidden lg:block">
                <Button to="https://app.lucidreach.com/" width={`w-12 lg:w-auto`} text="Get early access" borderRadius="rounded-14px" />
            </div>

            <div className="block lg:hidden" onClick={()=> setShowSideBar(true)}>
                <Collapse/>
            </div>
        </div>
    )
}

export default Header
